<template>
  <div>
    <v-card
        to="/was_ist/Drache">
      <v-row style="height: 100%">
        <v-col class="py-0" cols="6">
          <v-img
              style="border-top-left-radius: 4px; border-bottom-left-radius: 4px"
              height="100%"
              src="/Bilder/Drache/Drache_Card.png" alt="Drache">
          </v-img>
        </v-col>
        <v-col cols="6">
          <v-row
              class="flex-column ma-0"
              justify="start">
            <v-col class="py-0">
              <h4><b>Der Drache</b></h4>
            </v-col>
            <v-col class="">
              <p class="body-1"><b>Aufführung:</b><br>März/April 2025</p>
              <br><br>
              <small style="height: 20%">Für Details klicken</small>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "DracheCard"
}
</script>
